import { useTranslation } from "react-i18next";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
} from "@chakra-ui/react";
import PostCard from "../PostCard";
import { Comment, Post } from "@/schemaTypes";
import { Ico } from "@/assets/icons";
import CommentCard from "../../Comment/CommentCard";
import { PostDetailProps } from "../utils";
import CreateComment from "../../Comment/CreateComment";
import { GetCommentsByPostIdDocument } from "../graphql/GetCommentsByPostId.generated";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { noCacheHeaders } from "@/utils/headers";

const PostDetail = ({
  isOpen,
  onClose,
  selectedPost,
  refetchPosts,
}: PostDetailProps) => {
  const { t } = useTranslation();

  const [comments, setComments] = useState<Comment[]>([]);
  const [loadComments, { data, refetch }] = useLazyQuery(
    GetCommentsByPostIdDocument,
    {
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
      variables: { postId: selectedPost?._id as string }, // eslint-disable-line
    }
  );

  useEffect(() => {
    if (isOpen) {
      loadComments();
    }
  }, [isOpen, refetchPosts]);

  useEffect(() => {
    if (data?.getCommentsByPostId) {
      setComments(data.getCommentsByPostId as Comment[]);
    }
  }, [data]);

  return (
    <Drawer onClose={onClose} isOpen={isOpen} placement="bottom">
      <DrawerOverlay />
      <DrawerContent
        py={{ base: "0px", lg: 8 }}
        justifyContent={"space-between"}
        alignItems={"center"}
        height={{ base: "85vh", lg: "100vh" }}
        borderTopRadius={{ base: "6px", lg: "0px" }}
      >
        <DrawerHeader
          p={{ base: "16px", lg: "24px" }}
          w={{ base: "100%", lg: "798px" }}
          pt={{ base: "21px", lg: "32px" }}
          borderTopRadius={{ base: "6px", lg: "0px" }}
          backgroundColor={"white"}
        >
          <Button
            gap={3}
            w={"fit-content"}
            leftIcon={<Ico.ArrowLeft width={"24px"} height={"24px"} />}
            onClick={onClose}
            variant="default"
            fontSize={"14px"}
            px={0}
          >
            {t("Back")}
          </Button>
        </DrawerHeader>

        <DrawerBody
          display={"flex"}
          flexDir={"column"}
          py={0}
          px={4}
          gap={4}
          w={{ base: "100%", lg: "798px" }}
          css={{
            "&::-webkit-scrollbar": { width: "0px", height: "0px" },
          }}
        >
          <PostCard
            isPostSelected={true}
            post={selectedPost as Post}
            refetchPosts={refetchPosts}
          />

          <Flex flexDir={"column"} gap={4}>
            {comments?.map((comment, index) => (
              <CommentCard
                key={index}
                comment={comment as Comment}
                refetchPosts={refetchPosts}
                selectedPost={selectedPost as Post}
                refetchComments={refetch}
              />
            ))}
          </Flex>
        </DrawerBody>

        <DrawerFooter
          w={{ base: "100%", lg: "798px" }}
          display={{ base: "flex", lg: "none" }}
          pt={4}
          pb={6}
          px={4}
          backgroundColor={"white"}
        >
          <CreateComment
            selectedPost={selectedPost as Post}
            refetchPosts={refetchPosts}
            refetchComments={refetch}
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default PostDetail;
