import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  Text,
  useToast,
  Box,
} from "@chakra-ui/react";
import { Ico } from "@/assets/icons";
import { useTranslation } from "react-i18next";
import { ForumMenuProps } from "./utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DeletePostDocument } from "../Post/graphql/DeletePost.generated";
import { DeleteCommentDocument } from "../Post/graphql/DeleteComment.generated";
import { MuteUserDocument } from "../Post/graphql/MuteUser.generated";
import { UnmuteUserDocument } from "../Post/graphql/UnmuteUser.generated";
import { BanUserDocument } from "../Post/graphql/BanUser.generated";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { ValidateBannedUserDocument } from "../Post/graphql/ValidateBannedUser.generated";
import { useEffect, useState } from "react";

const MenuForum = ({
  canEdit,
  user,
  author,
  type,
  postId,
  commentId,
  mutedUsers,
  refetchPosts,
  refetchComments,
}: ForumMenuProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [deletePost] = useMutation(DeletePostDocument);
  const [deleteComment] = useMutation(DeleteCommentDocument);
  const [muteUser] = useMutation(MuteUserDocument);
  const [unmuteUser] = useMutation(UnmuteUserDocument);
  const [banUser] = useMutation(BanUserDocument);
  const [validateBannedUser] = useLazyQuery(ValidateBannedUserDocument);
  const [isBannedUser, setIsBannedUser] = useState(false);
  const [isMutedUser, setIsMutedUser] = useState(false);

  const toastMessage = (message: string) => {
    toast({
      title: t(message),
      position: "top",
      status: "success",
      variant: "subtle",
      duration: 4000,
      containerStyle: {
        borderRadius: "8px",
        border: "2px solid",
        borderColor: "success.300",
      },
    });
  };

  const handleValidateBannedUser = async () => {
    try {
      const response = await validateBannedUser({
        variables: { usersIds: [author.id] },
      });
      setIsBannedUser(response.data?.searchById[0].isBanned as boolean);
    } catch (error) {
      const errorMessage = (error as Error).message;
      SBErrorPubSub.publish({
        component: "Forum/Menu/index",
        message: errorMessage || "Error validate banned user",
        showInProd: true,
      });
    }
  };

  const handleValidateMutedUser = () => {
    if (mutedUsers?.includes(author.id)) {
      setIsMutedUser(true);
    } else {
      setIsMutedUser(false);
    }
  };

  useEffect(() => {
    handleValidateMutedUser();
  }, [author.id]);

  useEffect(() => {
    handleValidateBannedUser();
  }, [author.id]);

  const handleDelete = async () => {
    try {
      if (type === "Post" && postId) {
        await deletePost({
          variables: { postId: postId },
        });
        refetchPosts(postId);
      } else if (type === "Comment" && commentId) {
        await deleteComment({
          variables: { commentId: commentId },
        });
        refetchComments?.();
      }
    } catch (error) {
      const errorMessage = (error as Error).message;
      SBErrorPubSub.publish({
        component: "Forum/Menu/index",
        message: errorMessage || "Error al eliminar el post o comentario",
        showInProd: true,
      });
    }
  };

  const handleBanUser = async () => {
    try {
      const banUserResponse = await banUser({
        variables: { userId: author.id },
      });
      if (banUserResponse.data?.banUser === true) {
        toastMessage("User blocked");
        setIsBannedUser(true);
      } else {
        toastMessage("User unblocked");
        setIsBannedUser(false);
      }
    } catch (error) {
      const errorMessage = (error as Error).message;
      SBErrorPubSub.publish({
        component: "Forum/Menu/index",
        message: errorMessage || "Error ban user",
        showInProd: true,
      });
    }
  };

  const handleMuteUser = async () => {
    try {
      if (isMutedUser) {
        await unmuteUser({
          variables: { userId: author.id, postId: postId as string },
        });
        toastMessage("User unmuted");
        setIsMutedUser(false);
      } else {
        await muteUser({
          variables: { userId: author.id, postId: postId as string },
        });
        toastMessage("User muted");
        setIsMutedUser(true);
      }
    } catch (error) {
      const errorMessage = (error as Error).message;
      SBErrorPubSub.publish({
        component: "Forum/Menu/index",
        message: errorMessage || "Error mute user",
        showInProd: true,
      });
    }
  };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        variant={"ghost"}
        aria-label={t("Forum Options")}
        color={"secondary.300"}
        minW={"32px"}
        px={0}
        py={0}
        h={"32px"}
        icon={<Ico.VerticalDotsFill fontSize={"19px"} />}
      />
      <MenuList
        display="flex"
        flexDirection="column"
        w={"304px"}
        rounded={4}
        borderWidth="1px"
        p={0}
        h={"100%"}
        zIndex={1500}
      >
        {author.id !== user?.id && canEdit && (
          <>
            {false && (
              <MenuItem
                display={"flex"}
                flexDir={"row"}
                p={3}
                gap={"10px"}
                onClick={handleMuteUser}
                value="enabled"
              >
                <Ico.VolumeSlash width={"24px"} height={"24px"} />
                <Box display="inline">
                  {isMutedUser ? (
                    <Text as="span">{t("Unmute")}</Text>
                  ) : (
                    <Text as="span">{t("Mute")}</Text>
                  )}
                  <Text as="span" pl={2} variant={"bold"}>
                    {author.name}
                  </Text>
                </Box>
              </MenuItem>
            )}
            <MenuItem
              display={"flex"}
              flexDir={"row"}
              p={3}
              gap={"10px"}
              onClick={handleBanUser}
              value="enabled"
            >
              <Ico.UserX width={"24px"} height={"24px"} />
              <Box display="inline">
                {isBannedUser ? (
                  <Text as="span"> {t("Unblock")}</Text>
                ) : (
                  <Text as="span"> {t("Block")}</Text>
                )}
                <Text as="span" pl={2} variant="bold">
                  {author.name}
                </Text>
              </Box>
            </MenuItem>
          </>
        )}
        {(author.name === `${user?.name} ${user?.lastName}` || canEdit) && (
          <MenuItem p={3} gap={"10px"} onClick={handleDelete} value="enabled">
            <Ico.Trash color="#69696C" width={"24px"} height={"24px"} />
            <Text>
              {type === "Comment" ? t("Delete comment") : t("Delete post")}
            </Text>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

export default MenuForum;
