import { useUser } from "@/providers/useUser";
import { Comment, CommentInput, Post } from "@/schemaTypes";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { getHighestRole } from "../utils";

export interface CreateCommentProps {
  selectedComment?: Comment;
  selectedPost: Post;
  refetchPosts: () => void;
  refetchComments?: () => void;
}

export interface CommentCardProps {
  comment: Comment;
  selectedPost: Post;
  refetchPosts: () => void;
  refetchComments: () => void;
}

export const useCommentValidation = () => {
  const { t } = useTranslation();
  return object({
    comment: string().required(t("El campo es requerido")),
  });
};

export const useInitialValues = (
  selectedPost: Post,
  selectedComment?: Comment
): CommentInput => {
  const { user } = useUser();
  const role = getHighestRole(user?.roles || []);
  return {
    author: {
      id: selectedComment?.author?.id || user?.id,
      name: selectedComment?.author?.name || `${user?.name} ${user?.lastName}`,
      role: selectedComment?.author?.role || role,
    },
    comment: selectedComment?.comment || "",
    postId: selectedComment?.postId || selectedPost._id || "", // eslint-disable-line no-underscore-dangle
  };
};
