import { Ico } from "@/assets/icons";
import { Box, Divider, Flex, Text, useDisclosure } from "@chakra-ui/react";
import CreateComment from "../../Comment/CreateComment";
import PostDetail from "../PostDetail";
import { PostCardProps } from "../utils";
import { calculateTimeAgo } from "../../utils";
import { Button } from "@/components/Button";
import IconButton from "@/components/Button/Icon";
import { validateRole } from "../../utils";
import AvatarForum from "../../AvatarForum";
import MenuForum from "../../Menu";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "@/AppRoute";
import { useUser } from "@/providers/useUser";
import { UserRole } from "@/schemaTypes";

const PostCard = ({ post, isPostSelected, refetchPosts }: PostCardProps) => {
  const { user, hasRoles } = useUser();
  const canEdit = hasRoles([UserRole.Admin, UserRole.Moderator]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const rolePrivileges = validateRole(post?.author.role as string);
  const {
    isOpen: openDetail,
    onOpen,
    onClose: handleResetDetail,
  } = useDisclosure();
  let timeAgo = "Fecha desconocida";
  if (post.createdAt) {
    timeAgo = calculateTimeAgo(post.createdAt);
  }

  const navigateMentor = () => {
    navigate(`${AppRoute.Consulting}/${post.author.name}`, {
      replace: true,
    });
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      border={isPostSelected ? "0px" : "1px"}
      borderColor={"neutral.100"}
      borderRadius="6px"
      padding={isPostSelected ? 0 : { base: "16px", lg: "24px" }}
      gap={"16px"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        alignContent={{ base: "flex-start" }}
        width={"100%"}
        gap={isPostSelected ? 0 : { base: 4, lg: 6 }}
      >
        <Flex
          w={"100%"}
          alignItems={"baseline"}
          justifyContent={"space-between"}
        >
          <Flex alignItems={"flex-start"} gap={"20px"} w={"100%"}>
            <AvatarForum
              w={{ base: "32px", lg: "40px" }}
              author={post.author}
            />
            <Flex
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={{ base: "4px", lg: "6px" }}
            >
              <Text
                fontFamily={"Inter"}
                fontStyle={"normal"}
                fontSize={{ base: "16px", lg: "16px" }}
                fontWeight={"600"}
                lineHeight={"25.6px"}
                color={"secondary.300"}
              >
                {post.author.name}
              </Text>
              <Flex
                alignItems={{ base: "start", lg: "center" }}
                gap={"4px"}
                flexDir={{ base: "column", lg: "row" }}
              >
                <Flex alignItems={"center"}>
                  {rolePrivileges && (
                    <Flex alignItems={"center"} gap={"6px"}>
                      {post?.author.role === "TUTOR" ? (
                        <Ico.GeneralCheckDark fontSize="20px" />
                      ) : (
                        <Ico.GeneralCheck fontSize="20px" />
                      )}

                      <Text
                        fontSize={"14px"}
                        fontWeight={"bold"}
                        color={"secondary.300"}
                        textTransform={"capitalize"}
                      >
                        {post.author.role?.toLocaleLowerCase()}
                      </Text>
                    </Flex>
                  )}
                  {post.author.role === "MENTOR" && (
                    <>
                      <Box mx={1}>
                        <Ico.Circle2 fontSize="4px" />
                      </Box>
                      <Text
                        onClick={navigateMentor}
                        cursor={"pointer"}
                        fontSize={"14px"}
                        fontFamily={"Inter"}
                        fontWeight={"600"}
                        fontStyle={"normal"}
                        color={"primary.300"}
                        lineHeight={"22.4px"}
                      >
                        Agendar una sesión
                      </Text>
                    </>
                  )}
                </Flex>
                <Text
                  fontFamily={"Inter"}
                  fontStyle={"normal"}
                  fontSize={{ base: "14px", lg: "14px" }}
                  fontWeight={"400"}
                  lineHeight={"22.4px"}
                  color={"secondary.200"}
                >
                  {timeAgo}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          {(post.author.name === `${user?.name} ${user?.lastName}` ||
            canEdit) && (
            <MenuForum
              canEdit={canEdit}
              user={user}
              author={post.author}
              type="Post"
              postId={post._id as string} // eslint-disable-line no-underscore-dangle
              refetchPosts={refetchPosts}
              mutedUsers={post.mutedUsers as string[]}
            />
          )}
        </Flex>
        <Text
          onClick={onOpen}
          w={"100%"}
          cursor={"pointer"}
          fontFamily={{ base: "Inter", lg: "Poppins" }}
          fontStyle={"normal"}
          fontSize={{ base: "16px", lg: "20px" }}
          fontWeight={"600"}
          lineHeight={{ base: "25.6px", lg: "30px" }}
          color={"secondary.300"}
          textAlign={{ base: "left", lg: "left" }}
          pt={isPostSelected ? 4 : 0}
        >
          {post.content}
        </Text>
        <Divider
          mt={isPostSelected ? 6 : 0}
          height="1px"
          backgroundColor={"neutral.200"}
          border={"none"}
        />
      </Box>
      <Flex
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
        height={{ base: "28px", lg: "28px" }}
        onClick={onOpen}
        cursor={"pointer"}
      >
        <Flex
          justifyContent={"space-between"}
          flexDirection={"column"}
          padding={"4px 16px"}
          gap={"24px"}
          borderRadius={"6px"}
          bg={"neutral.50"}
        >
          <Text
            fontFamily={"Inter"}
            fontStyle={"normal"}
            fontSize={{ base: "12px", lg: "12px" }}
            fontWeight={"500"}
            lineHeight={"19.2px"}
            color={"secondary.300"}
          >
            {post.tags[0]}
          </Text>
        </Flex>
        <Flex
          gap={"4px"}
          alignItems={"center"}
          justifyContent={"center"}
          cursor={"pointer"}
        >
          <IconButton
            ariaLabel={t("comments")}
            boxSize="16px"
            justifyContent={"end"}
            icon={<Ico.MessageText fontSize={"16px"} />}
            onClick={() => {}}
            variant="text"
          />
          <Button
            variant="text"
            fontWeight={"400"}
            fontSize={{ base: "14px", lg: "14px" }}
            lineHeight={"22.4px"}
            title={`${post.totalComments} comentarios`}
          />
        </Flex>
      </Flex>
      <Box
        display={{ base: "none", lg: "flex" }}
        flexDirection={"column"}
        alignItems={"flex-start"}
        gap={"16px"}
        alignSelf={"stretch"}
        width={"100%"}
      >
        <Divider
          display={isPostSelected ? "none" : "flex"}
          height="1px"
          backgroundColor={"neutral.200"}
          border={"none"}
        />
        <CreateComment selectedPost={post} refetchPosts={refetchPosts} />
      </Box>
      <Divider
        display={isPostSelected ? "flex" : "none"}
        height="1px"
        backgroundColor={"neutral.200"}
        border={"none"}
      />
      {!isPostSelected && (
        <PostDetail
          isOpen={openDetail}
          onClose={handleResetDetail}
          selectedPost={post}
          refetchPosts={refetchPosts}
        />
      )}
    </Box>
  );
};
export default PostCard;
